import "./assets/css/App.css";
//import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter.jsx";
import { SnipcartProvider } from "use-snipcart"
import React, { useEffect } from "react";
import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-C6XS83ZMX9"; //Google Analytics

const App = () => {
    useEffect(() => {
      ReactGA.initialize(GA_TRACKING_ID);
      ReactGA.send("pageview");
    }, []);
  
    return (
      <BrowserRouter>
        <SnipcartProvider>
          <AppRouter />
        </SnipcartProvider>
      </BrowserRouter>
    );
  };
  
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

/*const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <SnipcartProvider>
                <AppRouter />
            </SnipcartProvider>
        </BrowserRouter>
   </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
