import React from "react";
// import { useState, CSSProperties } from "react";
import { RiseLoader } from "react-spinners";
// import "../assets/css/Login.css";
import { makeStyles } from '@material-ui/core/styles'
// import { textAlign } from "@mui/system";

const useStyles = makeStyles(() => ({
  container: {
      position: 'absolute',
      top: '50%',
      right: '50%',
      transform: 'translate(50%, -50%)'
  },
}));

export default function Loading() {
    const classes = useStyles();
    return <RiseLoader  className={classes.container} color="#d0d8e4"
    size={50} />;
  }